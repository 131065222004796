.stack {
  display: flex;
  flex-direction: column;
}

.backBtn {
  background-color: transparent;
  color: white;
  margin-top: 1rem;
  height: 2rem;
}

.gameFrame {
  width: 360px;
  height: 800px;
}