.App {
  text-align: center;
  background: linear-gradient(to left top, #111316, #4d5461);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2vmin;
  color: white;
}
