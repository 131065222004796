.mainheader {
  max-width: 60vmin;
  box-shadow: 1px 1px 10px 5px rgb(83, 83, 83);
  border-radius: 5px;
  margin: 5vmin;
  background-color: #2e333b;
}

.title {
  margin: 10px 20px;
  font-size: 5vmin;
  font-weight: bolder;
}