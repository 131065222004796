.imageContainer {
  position: absolute;
  top: 10%;
}

.tileParent {
  position: absolute;
  padding: 0;
  margin: 0;
}

.image {
  position: relative;
  padding: 0;
}