.card {
  margin: 0;
  max-width: 35vmin;
  box-shadow: 1px 1px 10px 5px rgb(83, 83, 83);
  border-radius: 5px;
  padding: 10px;
  background-color: #2e333b;
}

.image {
  width: 90%;
  border-radius: 5px;
}

button {
  background-color: transparent;
  color: white;
  font-size: 1rem;
  border-radius: 6px;
}