.tile {
  animation: spinning 4s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background-color: #2e333b;
  min-height: 100%;
  margin: 0;
  padding: 0;
  z-index: 10;
}

@keyframes spinning {
  0% {transform: rotateY(0deg); opacity: 1;}
  25% {transform: rotateY(180deg);}
  50% {transform: rotateY(360deg); opacity: 0.5;}
  75% {transform: rotateY(180deg);}
  100% {transform: rotateY(360deg); opacity: 0;}
}